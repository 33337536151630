import { classnames } from "tailwindcss-classnames"
import { FooterOption } from "@tilnet/lagaat-domain"

import SocialLinks from './SocialLinks'

import {pagePadding} from '../../lib/tailwind/styles'

type FooterItemProps =  {
    text?: string,
    className?: string
}

const itemDecoStyle = `last:border-l-0 ${classnames("sm:border-l")}`

const FooterItem = ({text, className}: FooterItemProps) => {
    return (
        <>
        { text &&
            <span className={`${className} ${classnames("text-lg", "leading-none","sm:px-4")}`} dangerouslySetInnerHTML={{__html: text}} />
        }
        </>
    )
}

const BottomFooter = (footerOpts: FooterOption) => {
    const { address, copyrights, designCredit, devCredit, email, name, phone, socialLinks} = footerOpts
    return (
        <div className={`2xl:max-w-screen-xl1/2 ${classnames(
            pagePadding, 
            "text-white", 
            "py-8", 
            "flex", 
            "flex-wrap", 
            "gap-4", 
            "sm:gap-0", 
            "font-medium", 
            "justify-center",
            "items-center",
            "2xl:mx-auto",
            "2xl:px-12",
            "2xl:justify-between"     
        )}`}>
            <FooterItem text={name} className={itemDecoStyle} />
            <FooterItem text={address} className={itemDecoStyle} />
            <FooterItem text={phone} className={itemDecoStyle} />
            <FooterItem text={email} className={itemDecoStyle} />

            {socialLinks && 
                <div className="w-full lg:w-auto">
                    <div className={classnames(
                        "w-36", 
                        "sm:w-40", 
                        "lg:w-auto", 
                        "mx-auto", 
                        "my-4", 
                        "sm:my-10", 
                        "lg:my-0", 
                        "flex", 
                        "content-center", 
                        "justify-center", 
                        "pt-5", 
                        "lg:pt-0",
                        "pb-4",
                        "lg:pb-0",
                        "border-t", 
                        "border-white", 
                        "border-b", 
                        "lg:border-b-0",
                        "border-opacity-80", 
                        "sm:border-t-0"
                    )}>
                        <SocialLinks socialLinks={socialLinks} />

                    </div>
                </div>
            }

            <div className={classnames(
                "font-light", 
                "flex", 
                "flex-wrap", 
                "content-center", 
                "justify-center", 
                "gap-4",
                "lg:mt-6",
                "2xl:mt-0"
            )}>
                <FooterItem text={copyrights} className={classnames("w-full", "lg:w-auto", "text-center")} />
                <FooterItem text={designCredit} />
                <FooterItem text={devCredit} />

            </div>

        </div>
    )
}

export default BottomFooter